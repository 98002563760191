

(function () {
    canvas = document.getElementById('mycanvas');
    if (canvas) {
        var width, height, largeHeader, canvas, ctx, circles, target, animateHeader = true;

        // Main
        initHeader();
        addListeners();

        function initHeader() {
            width = window.innerWidth;
//                width = document.body.scrollWidth;
            height = window.innerHeight;
//            height = 700;
            target = {x: 0, y: height};

            largeHeader = document.getElementById('large-header');
            largeHeader.style.height = height + 'px';



            myColor = $('canvas').css('color').replace('rgb', 'rgba');
            myColor = myColor.slice(0, -1);
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext('2d');

            // create particles
            circles = [];
            for (var x = 0; x < width * 0.1; x++) {
                var c = new Circle();
                circles.push(c);
            }
            animate();
        }

        // Event handling
        function addListeners() {
            window.addEventListener('scroll', scrollCheck);
            window.addEventListener('resize', resize);
        }

        function scrollCheck() {
            if (document.body.scrollTop > height)
                animateHeader = false;
            else
                animateHeader = true;
        }

        function resize() {
            width = window.innerWidth;
            height = window.innerHeight;
            largeHeader.style.height = height + 'px';
            canvas.width = width;
            canvas.height = height;
        }

        function animate() {

            if (animateHeader) {
                ctx.clearRect(0, 0, width, height);
                for (var i in circles) {
                    circles[i].draw();
                    circles[i].draw2();
                }
            }
            requestAnimationFrame(animate);
        }

        // Canvas manipulation
        function Circle() {
            var _this = this;

            // constructor
            (function () {
                _this.pos = {};
                init();
//            console.log(_this);
            })();

            function init() {
                _this.pos.x = Math.random() * width;
                _this.pos.y = height + Math.random() * 100;
                _this.alpha = 0.1 + Math.random() * 0.5;
                _this.scale = 0.1 + Math.random() * 0.5;
                _this.velocity = Math.random();
            }

            this.draw = function () {
                if (_this.alpha <= 0) {
                    init();
                }
                _this.pos.y -= _this.velocity;
                _this.alpha -= 0.0005;
                ctx.beginPath();
//             console.log(myColor+_this.alpha + ")");
                myColor = $('canvas').css('color').replace('rgb', 'rgba');
                myColor = myColor.slice(0, -1);
                ctx.fillStyle = myColor + "," + _this.alpha + ")";
                ctx.beginPath();
                ctx.bezierCurveTo(_this.pos.x + 24, _this.pos.y + 12, _this.pos.x + 17, _this.pos.y + 5, _this.pos.x + 11, _this.pos.y + 12);
                ctx.bezierCurveTo(_this.pos.x + 5, _this.pos.y + 18, _this.pos.x + 18, _this.pos.y + 28, _this.pos.x + 24, _this.pos.y + 32);
                ctx.bezierCurveTo(_this.pos.x + 30, _this.pos.y + 26, _this.pos.x + 41, _this.pos.y + 21, _this.pos.x + 38, _this.pos.y + 14);
                ctx.bezierCurveTo(_this.pos.x + 35, _this.pos.y + 6, _this.pos.x + 24, _this.pos.y + 11, _this.pos.x + 24, _this.pos.y + 12);

                ctx.arc(_this.pos.x, _this.pos.y, _this.scale * 15, 0, 2 * Math.PI, false);
                ctx.fill();
            };
            this.draw2 = function () {
                if (_this.alpha <= 0) {
                    init();
                }
                _this.pos.y -= _this.velocity;
//            _this.alpha -= 0.0005;
                ctx.beginPath();
                ctx.fillStyle = myColor + "," + _this.alpha + ')';
//            ctx.fillStyle = 'rgba(238,39,53,' + _this.alpha + ')';
                ctx.beginPath();

                ctx.bezierCurveTo(_this.pos.x + 75, _this.pos.y + 37, _this.pos.x + 70, _this.pos.y + 25, _this.pos.x + 50, _this.pos.y + 25);
                ctx.bezierCurveTo(_this.pos.x + 20, _this.pos.y + 25, _this.pos.x + 20, _this.pos.y + 62.5, _this.pos.x + 20, _this.pos.y + 62.5);
                ctx.bezierCurveTo(_this.pos.x + 20, _this.pos.y + 80, _this.pos.x + 40, _this.pos.y + 102, _this.pos.x + 75, _this.pos.y + 120);
                ctx.bezierCurveTo(_this.pos.x + 110, _this.pos.y + 102, _this.pos.x + 130, _this.pos.y + 80, _this.pos.x + 130, _this.pos.y + 62.5);
                ctx.bezierCurveTo(_this.pos.x + 130, _this.pos.y + 62.5, _this.pos.x + 130, _this.pos.y + 25, _this.pos.x + 100, _this.pos.y + 25);
                ctx.bezierCurveTo(_this.pos.x + 85, _this.pos.y + 25, _this.pos.x + 75, _this.pos.y + 37, _this.pos.x + 75, _this.pos.y + 40);
                ctx.fill();


            };
        }

    }
})();



